import React, { useState, useEffect, useRef } from 'react';
import styles from './SubNavigation.module.scss';
import classNames from 'classnames';
import i18n from '../../i18n';
import PropTypes from 'prop-types';
import { dataLayerPush } from '../../utils/datalayer';
import { capitalizeFirstLetter } from '../../utils/helpers';

const SubNavigation = ({
    label = '',
    href = '/',
    id,
    children = [],
    navActivePages = [],
}) => {
    const [showNav, setVisible] = useState(false);
    const container = useRef(null);
    const content = useRef(null);
    const subnav = React.createRef();
    const reloadOffset = false;
    let offsetTop = 800;

    const scrollNav = () => {
        //TODO - scroll to child position
        container.current.scrollLeft += 80;
    };
    const useResize = (container, content) => {
        useEffect(() => {
            const handleResize = () => {
                if (container.current !== null) {
                    if (container.current.offsetWidth < 754) {
                        offsetTop = 495;
                    } else {
                        offsetTop = 800;
                    }

                    if (children.length >= 1) {
                        setVisible(
                            container.current.offsetWidth <=
                                content.current.offsetWidth
                        );
                    }
                }
            };

            window.addEventListener('resize', handleResize);
            handleResize();

            return () => {
                window.removeEventListener('resize', handleResize);
            };
        }, [container, content]);
        return {};
    };

    useResize(container, content);

    const [fixed, setFixed] = React.useState(false);

    const handleScroll = () => {
        const offset = window.scrollY;
        if (offset > offsetTop) {
            setFixed(true);
        } else {
            setFixed(false);
        }
    };

    useEffect(() => {
        //offsetTop = subnav.current.offsetTop;
        window.addEventListener('scroll', handleScroll);
    }, [reloadOffset]);

    const itemClasses = classNames(styles['SubNavigation'], {
        [styles['SubNavigation--Overflow']]: showNav,
        [styles['SubNavigation--Fixed']]: fixed,
    });

    const isMainCategoryPage = navActivePages.includes(id);
    const LabelTag = isMainCategoryPage ? 'h1' : 'p';
    return (
        <nav
            aria-label={i18n.t('Nav.sub')}
            ref={subnav}
            className={itemClasses}>
            {label && (
                <LabelTag className={styles['SubNavigation__Title']}>
                    {label}
                </LabelTag>
            )}

            {children && children.length > 0 && (
                <div className={styles['SubNavigation__Grid']}>
                    <div
                        className={styles['SubNavigation__Container']}
                        ref={container}>
                        <ul
                            className={styles['SubNavigation__List']}
                            ref={content}>
                            <SubNavItem
                                label={i18n.t('SubNavigation.inFocus')}
                                href={href}
                                active={navActivePages.includes(id)}
                            />
                            {children.map((item, i) => (
                                <SubNavItem
                                    key={i}
                                    {...item}
                                    active={navActivePages.includes(item?.id)}
                                />
                            ))}
                        </ul>
                    </div>
                    <div
                        onClick={scrollNav}
                        className={styles['SubNavigation__Icon--More']}>
                        <div
                            className={
                                styles['SubNavigation__Icon--More__Arrow']
                            }></div>
                    </div>
                </div>
            )}
        </nav>
    );
};

const SubNavItem = ({ href, label, active }) => (
    <li className={styles['SubNavigation__Item']}>
        <a
            className={classNames(styles['SubNavigation__ItemLink'], {
                [styles['SubNavigation__ItemLink--Active']]: active,
            })}
            onClick={(e) =>
                dataLayerPush({
                    event: 'menu',
                    eventCategory: 'Menu',
                    eventAction: '2',
                    eventLabel: capitalizeFirstLetter(label),
                })
            }
            href={href}>
            {active && (
                <h1 className={styles['SubNavigation__ItemLabel']}>{label}</h1>
            )}
            {!active && (
                <span className={styles['SubNavigation__ItemLabel']}>
                    {label}
                </span>
            )}
            {active && (
                <span className="sr-only">({i18n.t('generic.current')})</span>
            )}
        </a>
    </li>
);

SubNavItem.propTypes = {
    href: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    active: PropTypes.bool,
};

SubNavigation.propTypes = {
    href: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    active: PropTypes.bool,
    children: PropTypes.array,
    navActivePages: PropTypes.array,
};

export default SubNavigation;
